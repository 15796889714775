import React, {useEffect, useMemo} from 'react';
import {Grid, Box} from '@material-ui/core';
import IconWithTooltip from '../../Tooltip/IconWithTooltip';
import TextField from '../../TextField/TextField';
import {CreateNewPortalUserFormData, portalUserSettingsFieldsName, useStyles} from '../utils';
import {useTranslation} from 'react-i18next';
import {useFormikContext} from 'formik';
import SelectField from '../../SelectField/SelectField';
import CustomKeyboardDateTimePicker from '../../KeyboardDateTimePicker/KeyboardDateTimePicker';
import {useDispatch, useSelector} from 'react-redux';
import {APIErrorInterface, ReduxState} from '../../../store/types';
import {ConfigData} from '../../../store/types/ConfigData';
import PasswordTextField from '../../PasswordTextField/PasswordTextField';
import {getConfigFromPasswordRulesOrDefault} from '../../../utils/passwordGenerator';
import classNames from 'classnames';
import {PortalUsersErrors} from '../../../services/apiErrors';
import {ErrorTabInteface} from "../../Forms/RingGroups/generalFormUtils";
import {actions} from "../../../store";
import {isAnyKeyFromSearchExistInSource} from "../../../utils/isAnyKeyFromSearchExistInSource";
import {usePermissionContext} from "../../../hooks/usePermissions";
import {PermissionType} from "../../../store/types/Permission";
import i18n from "../../../services/i18n";
import {convertCurrentDateToUserTimeZone} from '../../../views/PortalUsers/utils';
import ExtensionAsyncSelectFiled from "../../CallControls/ExtensionAsyncSelectFiled";
import {RoleUserItem} from "../../../store/actions/company/payloads";
import { LocaleLanguages } from '../../../store/types/TimeZone';

const PortalUserSettings: React.VFC<ErrorTabInteface & {
    isSupervisor?: boolean, roleList?: RoleUserItem[], apiErrors?: APIErrorInterface;
}> = (
    {
        tabIndex,
        tabName,
        callback,
        callback2,
        isSupervisor,
        roleList,
        apiErrors
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const permissions = usePermissionContext();

    const {timeZonesList, languagesList} = useSelector(
        (state: ReduxState) => state.generic,
    );
    const config = useSelector<ReduxState, ConfigData | undefined>(
        (state) => state.generic?.configData,
    );
    const userDateFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_date_format ||
            '',
    );

    const iTimeZone = useSelector(
        (state: ReduxState) => state.myProfile?.customer?.iTimeZone,
    );

    const {
        values,
        handleChange,
        errors,
        setFieldValue,
        setFieldError,
        initialValues,
    } = useFormikContext<CreateNewPortalUserFormData>();

    const markTabAsHaveError = (setTabError: boolean) => {
        if (tabName && tabIndex !== undefined) {
            dispatch(actions.setErrorInTab({name: tabName, index: tabIndex, markAsInvalid: setTabError}))
        }
    }

    const filteredLanguages = useMemo(() => {
        const langCodesAllowed = i18n?.options?.resources ? Object.keys(i18n?.options?.resources) : ['en'];
        const langs = languagesList?.filter(e => langCodesAllowed?.findIndex(c => c === e.iso_639_1) !== -1) ?? [];
        const selectLanguage = values?.language;
        if(selectLanguage && langs.findIndex(e => e.name === selectLanguage) === -1) {
            const name = 
                selectLanguage.includes(t('screens:myProfile.languageNotSupported'))
                ? selectLanguage
                : selectLanguage + t('screens:myProfile.languageNotSupported');
            const obj: LocaleLanguages = {
                name: name,
                direction: '',
                iso_639_1: ''
            }; 
            langs.push(obj);
            initialValues.language = name;
            setFieldValue('language', name);
        }
        return langs;
    }, [values, i18n, t, languagesList, initialValues]);

    useEffect(() => {

        const errorsKeys = Object.keys(errors);
        if (isAnyKeyFromSearchExistInSource(errorsKeys, portalUserSettingsFieldsName)) {
            markTabAsHaveError(true)
        } else {
            markTabAsHaveError(false)
        }

    }, [errors, values]);

    useEffect(() => {
        if (
            apiErrors?.faultstring.includes(PortalUsersErrors.PortalUserExist)
        ) {
            setFieldError(
                'login',
                t('errors:portalUsers.loginExists', {value: values.login}),
            );
            markTabAsHaveError(true);
            callback && callback();
        }

        if (
            apiErrors?.faultstring.includes(PortalUsersErrors.PasswordError)
        ) {
            setFieldError(
                'password',
                i18n.t<string>('errors:extensions.portalPasswordUpdate'),
            );
            markTabAsHaveError(true);
            callback && callback();
        }

        if (
            apiErrors?.faultcode == 'Server.Customer.error_during_update_customer_individual'
        ) {
            setFieldError(
                'extension',
                t('errors:portalUsers.accountIsUsed')
            );
            markTabAsHaveError(true);
            callback && callback();
        }
    }, [apiErrors]);

    const minForExpiration = useMemo(() => {
        const tzname = timeZonesList.find((e) => e.i_time_zone === iTimeZone)
            ?.time_zone_name;
        const tomorrow = convertCurrentDateToUserTimeZone(tzname);
        tomorrow.setDate(tomorrow.getDate() + 1);
        if (!values.activationDate) {
            return tomorrow;
        }
        const minday = new Date(values.activationDate.toString()) || tomorrow;
        minday.setDate(minday.getDate() + 1);
        return minday;
    }, [values.activationDate, timeZonesList, iTimeZone]);

    const minDateForActivation = useMemo(() => {
        const tzname = timeZonesList.find((e) => e.i_time_zone === iTimeZone)
            ?.time_zone_name;
        return convertCurrentDateToUserTimeZone(tzname);
    }, [timeZonesList, iTimeZone]);

    const formatedRoleList = useMemo(() => {

        return roleList
            ?.filter((e) => e.name !== t('common:any', 'Any'))
            ?.map((v) => v.name) || []
    }, [roleList])


    const formatedIcon = useMemo(() => {
        return roleList?.find((e) => e.name === values.role)
            ?.description ? (
            <IconWithTooltip
                dataQa="my-company-address"
                tooltipText={
                    roleList?.find(
                        (e) => e.name === values.role,
                    )?.description || ''
                }
            />
        ) : undefined
    }, [roleList])

    // @ts-ignore
    return (
        <>
            <Grid
                item
                className={classNames(
                    classes.itemsContainer,
                    classes.marginBottom,
                )}
                data-testid={'edit-user-settings'}
            >
                <Box className={classes.headerBox} width={350}>
                    <span className={classes.rowBoxHeader}>
                        {t('screens:myCompany.general')}
                    </span>

                    <IconWithTooltip
                        dataQa="my-company-address"
                        tooltipText={t('tooltips:myCompany.general')}
                    />
                </Box>
                <Box className={classes.rowBox}>
                    <TextField
                        id="login"
                        label={t('common:login')}
                        onChange={handleChange}
                        value={values.login}
                        setFieldError={setFieldError}
                        dataQa="create-portal-user-login"
                        helperText={errors?.login}
                        required
                        maxLength={64}
                    />

                    <PasswordTextField
                        id="password"
                        label={t('common:password')}
                        value={values.password}
                        onChange={handleChange}
                        dataQa="create-extension-name-input"
                        setFieldError={setFieldError}
                        error={errors.password}
                        isRequired={true}
                        passwordRulesConfig={getConfigFromPasswordRulesOrDefault(
                            // @ts-ignore
                            config,
                            true
                        )}
                        setFieldValue={setFieldValue}
                        maxLength={32}
                        inputProps={{
                            inputProps: {
                                min:
                                    config?.Web.PasswordMinLength || 0,
                                maxLength: 32,
                            },
                        }}
                        passwordProtectionMode
                        initialValue={initialValues.password}
                    />
                </Box>
                <Box className={classes.rowBox}>
                    <TextField
                        id="email"
                        label={t('common:emailAddress')}
                        onChange={handleChange}
                        value={values.email}
                        setFieldError={setFieldError}
                        dataQa="create-portal-user-email"
                        helperText={errors?.email}
                        maxLength={128}
                    />

                    {!isSupervisor && (
                        <SelectField
                            id="role"
                            onChange={(e, v) => {
                                setFieldValue('role', v)
                                if (callback2) {

                                    const role = roleList?.find(r => r.name == v)
                                    if (role?.i_role_type == 7) {
                                        callback2(true)
                                    } else {
                                        callback2(false)
                                    }
                                }

                            }}
                            label={t('screens:portalUsers.role')}
                            items={formatedRoleList}
                            value={values.role}
                            dataQa="create-portal-user-role"
                            readOnly={values?.isCurrentLoginUser || permissions == PermissionType.ReadOnly}
                            className={classNames(values?.isCurrentLoginUser || permissions == PermissionType.ReadOnly ? classes.readOnlyRole : null)}
                            classes={{iconContainer: classNames(values?.isCurrentLoginUser || permissions == PermissionType.ReadOnly ? classes.readOnlyRoleIconContainer : null)}}
                            icon={formatedIcon}
                            helperText={errors?.role}
                            required
                            setFieldError={setFieldError}
                        />
                    )}

                </Box>

                {roleList?.find(role => role.name == values.role)?.i_role_type == 7 && (
                    <Box className={classes.rowBox}>
                        <ExtensionAsyncSelectFiled
                            id={'extension'}
                            // @ts-ignore
                            value={values.extension}
                            setValue={(value) => {
                                setFieldValue('extension', value)
                            }}
                            customClass={classes.extensionField}
                            required={true}
                            useAny={false}
                            showRegisterStatus={false}
                            disableClearable={true}
                            forceEnable={true}
                            useExtensionFilter={true}
                            setFieldError={setFieldError}
                            helperText={errors.extension}
                        />
                    </Box>
                )}

                {isSupervisor && (
                    <Box className={classes.rowBox}>
                        <TextField label={t('common:type')} value={t('screens:addToRingGroup.supervisor').toString()}
                                   readOnly iconPosition={'end'} icon={
                            (<IconWithTooltip
                                dataQa="profile-roles"
                                tooltipText={t('tooltips:profiles.roles')}
                            />)
                        }/>
                    </Box>
                )}

            </Grid>

            <Grid item className={classes.itemsContainer}>
                <Box className={classes.headerBox} width={350}>
                    <span className={classes.rowBoxHeader}>
                        {t('screens:portalUsers.changeStatus')}
                    </span>

                    <IconWithTooltip
                        dataQa="my-company-address"
                        tooltipText={t('tooltips:myCompany.changeStatus')}
                    />
                </Box>
                <Box
                    className={classNames(classes.rowBox, classes.rowBoxSecond)}
                >
                    <CustomKeyboardDateTimePicker
                        id="activationDate"
                        label={t('screens:portalUsers.activationDate')}
                        value={values.activationDate}
                        onChange={(v) => setFieldValue('activationDate', v)}
                        dataQa={'portal-user-activation-date'}
                        dataTestId={'portal-user-activation-date'}
                        minDate={minDateForActivation}
                        userDateTimeFormat={userDateFormat}
                        allowEmptyDate={true}
                        withHours={false}
                        setFieldError={setFieldError}
                        helperText={errors?.activationDate}
                    />

                    {/* Temporary fix */}
                    <div style={{height: 56}}/>

                    <CustomKeyboardDateTimePicker
                        id="expirationDate"
                        label={t('screens:portalUsers.expirationDate')}
                        value={values.expirationDate}
                        onChange={(v) => setFieldValue('expirationDate', v)}
                        dataQa={'portal-user-expieration-date'}
                        dataTestId={'portal-user-activexpierationation-date'}
                        userDateTimeFormat={userDateFormat}
                        allowEmptyDate={true}
                        withHours={false}
                        minDate={minForExpiration}
                        setFieldError={setFieldError}
                        helperText={errors?.expirationDate}
                    />
                </Box>
                <Box
                    className={classNames(
                        classes.rowBox,
                        classes.timeZoneRow,
                        classes.rowBoxSecond,
                    )}
                >
                    <SelectField
                        id="timezone"
                        onChange={(e, v) => setFieldValue('timezone', v)}
                        label={t('common:timezone')}
                        items={
                            timeZonesList?.map((v) => v.time_zone_name) || []
                        }
                        value={values.timezone}
                        dataQa="create-portal-user-timezone"
                    />

                    <SelectField
                        id="language"
                        onChange={(e, v) => setFieldValue('language', v)}
                        label={t('common:language')}
                        items={filteredLanguages?.map((v) => v.name) || []}
                        value={values.language}
                        dataQa="create-portal-user-language"
                    />
                </Box>
            </Grid>
        </>
    );
};

export default PortalUserSettings;
